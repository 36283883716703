.App {
  /* width: 100vw; */
}
.setup {
  display: flex;
  flex-direction: row;
}

input {
  outline: none;
}
body {
  margin: 0;
  padding: 0;
  /* font-family: 'Acme', sans-serif; */
  font-family: "Mulish", sans-serif !important;
  letter-spacing: 0.4px;
  /* -webkit-user-select: none; Safari */
  /* -ms-user-select: none; IE 10 and IE 11 */

  /* user-select: none; Standard syntax */
}
/* .fixed-content {
  top: 0;
  bottom:0;
  position:fixed;
  overflow-y:scroll;
  overflow-x:hidden;
}
.fixed-content {
  overflow-y:scroll;
  overflow-x:scroll;
} */
.sidebar {
  /* width: 20%; */
  background-color: rgb(45, 45, 45);
  font-family: "Mulish", sans-serif;
  position: sticky;
  top: 0;
  /* min-width: max-content;
  max-width: max-content; */
  transition: all 250s ease-in-out;
  /* min-height: 100vh; */
  height: 100%;
}

.view_details_sidebar {
  margin: 1rem 0;
}
.plan_result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.75rem;
  position: relative;
}

.plan_result > :first-child {
  width: 70%;
  font-size: 0.7rem;
}

.plan_result > :nth-child(2) {
  width: 30%;
  font-size: 0.7rem;
  color: #29cc97;
}

.plan_result :nth-child(3) {
  cursor: pointer;
}

.infoItem {
  position: absolute;
  right: -130%;
  z-index: 100;
  width: 20rem;
  padding: 0.5rem;
  background-color: rgb(226, 224, 224);
  color: black;
  font-weight: bold;
  border-radius: 10px;
  font-size: 0.9rem;
}
.freeHover,
.paidHover,
.exchangeHover {
  display: none;
}

.exchangeLink:hover + .exchangeHover {
  display: block;
}

.paidLink:hover + .paidHover {
  display: block;
}

.freeGuestLink:hover + .freeHover {
  display: block;
}

.view_detail_sidebar_dropdown {
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.6rem;
  cursor: pointer;
}
.view_detail_sidebar_dropdown img {
  width: 1rem;
  height: 1rem;
  object-fit: contain;
}
body {
  background-color: #e5e5e5;
}

.box {
  border: 1px solid #dfdfdf;
  background-color: #fff;
}

.g-btn {
  width: 100% !important;
  height: 40px !important;
  line-height: 40px !important;
  font-size: 15px !important;
}
.g-btn > div,
.g-btn > div > svg {
  width: 40px !important;
  height: 38px !important;
}
.class1 {
  margin-top: 70px;
}
.Icon {
  filter: contrast(0.5);
}
.Navtext {
  color: rgb(215, 240, 240);

  text-decoration: none;
  /* font-size: large; */
  font-family: "Mulish", sans-serif;
}
.Navtext:hover {
  /* font-size: larger; */
  text-decoration: none;
}

.Navhover:hover {
  /* background-color: rgb(209, 228, 229); */
  cursor: pointer;

  /* color: black; */
}
.Navhover {
  /* margin-left: 25px; */
  padding: 1rem;
  height: 40px;
}
.boorder {
  border: 1px solid;
  border-radius: 30px;
}

.imagesize {
  height: 60px;
  width: 60px;
  border-radius: 100px;
}
.imagesizeuser {
  height: 50px;
  width: 50px;
  border-radius: 100px;
}
.customcard {
  width: 18rem;
  height: 150px;
  margin-top: 20px;
}
.cards1 {
  background-color: #e6f0f3;
}

.imageicon {
  height: 20px;
  width: 25px;
  margin-right: 4px;
  object-fit: contain;
}
.dot {
  height: 10px;
  width: 8px;
  background-color: #4b2ca9;
  border-radius: 50%;
  display: inline-block;
}
/* .one::after{
  margin-left: 8.255em;
} */

/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxx {
  /* 
  width: 45%; */
  /* height: 50rem; */
  border-radius: 10px;
  max-height: 85vh;
  height: 100%;
  min-height: max-content;
  /* margin-top: calc(100vh - 95vh - 10px); */
  /* margin-left: 40rem; */
  background: #fff;
  border: 0px solid #999;
  margin-left: 10%;
  overflow: hidden;
  /* z-index: 100; */
  /* margin: 5% 0 5% 10%; */
}

.boxx-down {
  overflow: scroll !important;
  max-height: 70vh !important;
  height: 90% !important;
  /* padding-bottom: 1rem !important; */
  /* height: max-content; */
  /* scroll-behavior: smooth; */
}
.boxx-down::-webkit-scrollbar-track {
  /* display: none; */
  /* scrollbar-color: red !important;
  scrollbar-width: thin !important; */
}
/* .boxx + .tooltip-wrap {
  position: absolute;
} */
/* #indexed:hover {
  cursor: pointer;
  content: "Reference ";

  } */

.tooltip-wrap {
  position: relative;
  z-index: 1;
}
.tooltip-wrap-desc {
  position: relative;
}
.tooltip-wrap .tooltip-content {
  display: none;
  position: absolute;
  top: 0%;
  /* top:-100px; */
  /* left: 5%; */
  /* right: 5%; */
  height: fit-content;
  bottom: -100%;
  background-color: #2c2626;
  color: whitesmoke;
  padding: 0.5em;
  border: 1px solid rgb(79, 48, 48);
  border-radius: 8px;
  width: 500px;
  font-size: small;

  /* /* word-wrap: break-word; */
  /* inline-size: 650px;
    overflow: hidden; */
}

.tooltip-wrap-desc .tooltip-content-desc {
  display: none;
  position: absolute;
  bottom: 50%;
  left: 95%;
  right: 0%;
  height: 50px;
  background-color: #2c2626;
  color: whitesmoke;
  padding: 0.5em;
  border: 1px solid #4f3030;
  border-radius: 8px;
  width: max-content;
  font-size: small;
  height: max-content;
  /* /* word-wrap: break-word; */
  inline-size: 350px;
  overflow: hidden;
}
.tooltip-wrap:hover .tooltip-content {
  display: block;
}
.tooltip-wrap-desc:hover .tooltip-content-desc {
  display: block;
}

.close-icon {
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.link {
  font-size: 15px;
  color: #9fa2b4;
}

.dropdown-height {
  min-height: 200px;
}

.header-values {
  margin-left: 5px;
}
.row-container-links {
  width: 100%;
  display: flex;
}
.column {
  width: 20%;
  box-sizing: border-box;
  /* border: 1px solid; */
  padding: 0.5rem;
}
.accepted-links-data {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  margin-bottom: 1rem;
}
.accepted-link {
  font-weight: 600;
  font-size: 15px;
  align-items: center;
}
.accepted-link-1 {
  font-size: 13px;
  font-weight: 600;
  color: #808080;
}
.accepted-link-2 {
  font-size: 13px;
  font-weight: 600;
}

.accepted-link-button {
  height: 3.5rem;
  background-color: #4b2ca9 !important;
  color: #fff !important;
  width: 100%;
}

.accepted-link-button-2 {
  height: 3.5rem;
  background-color: #fff !important;
  color: #4b2ca9 !important;
  width: 100%;
  border: 1px solid #4b2ca9 !important;
  margin-bottom: 5px;
}

.blur__on__linkvault {
  position: relative;
  z-index: 0;
  color: #dcdcdc;
}
.blur__on__linkvault::after {
  content: "";
  width: 100%;
  height: 80%;
  /* background-color: red; */
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
  background-color: #4f4f4f;
  opacity: 0.1;
  filter: blur(15px);
}

.links {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem 0.2rem 0;
}
.links img {
  cursor: pointer;
}

.links__data {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  font-weight: 600;
}

.view_details {
  font-size: 13px;
  color: #5541d7;
  cursor: pointer;
}

.popup_error_container {
  background-color: #fbdad3;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 10px;
  font-weight: 600;
  font-size: 13px;
}

.error_title {
  color: #ff5b39;
  display: block;
  margin-bottom: 0.05rem;
  font-size: 14px;
  text-align: center;
}

.popup_error {
  word-wrap: break-word;
  color: #ff5b39;
  text-align: center;
  font-size: 12px;
  display: block;
  margin-top: 0.5rem;
}

.popup_error span {
  color: blue;
}
.progress_bar {
  width: 20%;
  position: relative;
}
.exchange_progress {
  width: 30px;
  height: 30px;
  /* background-color: #29cc97; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
}
.progress_text {
  position: absolute;
  width: 70%;
  left: -25%;
  top: 100%;
  color: #707987;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
}

.progress_bar_giver {
  width: 25%;
  position: relative;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 50px 50px 50px 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #282d32;
  border-radius: 10px;
}

/* Manage Teams */
.invite-heading {
  color: #252733;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0.5rem 1rem;
}

.invite-heading :first-child {
  font-size: 1.3rem;
  letter-spacing: 0.0625rem;
}

.invite-section {
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
  padding: 1rem 2rem;
  column-gap: 2rem;
}
.invite-input {
  width: 20%;
}

.invite-input div {
  font-weight: 600;
  color: #828282;
  font-size: 0.9rem;
}

.invite-input input {
  width: 100%;
  padding: 0.5rem 1rem;
  outline: none;
  border-radius: 10px;
  border: 0.5px solid #828282;
}

.selection {
  width: 35%;
}

.selection-icons {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  width: 100%;
  column-gap: 0.5rem;
}
.selection-heading {
  font-weight: 600;
  font-size: 0.7rem;
  margin-bottom: 0.25rem;
}

.selection-heading ::first-letter {
  font-size: 0.9rem;
}

.selection-bar {
  color: #828282;
  font-size: 0.73rem;
  font-weight: 600;
  border-radius: 30px;
  border: 1px solid #828282;
  padding: 0.2rem 0.3rem;
  cursor: pointer;
}

.button_section {
  background-color: #5541d7;
  width: max-content;
  height: max-content;
  cursor: pointer;
  padding: 0.8rem 2rem;
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
}

.currentTeammates {
  padding: 0.5rem 2rem;
}

.current_buttons {
  display: flex;
  column-gap: 1rem;
  justify-content: space-around;
}

.current_buttons div {
  border: 1px solid #828282;
  padding: 0.4rem 0.5rem;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 450;
  color: #828282;
  transition: all 250ms;
  font-size: 0.9rem;
}

.current_buttons div:hover {
  background-color: #828282;
  color: #ffff;
}
.button_hover_animation {
  padding: 0.4rem 0.5rem;
  border-radius: 10px;
  transition: all 250ms;
}

.button_hover_animation:hover {
  background: #f1efef;
}

.accept_button {
}
.accept_button_hover {
  position: absolute;
  top: -25%;
  background-color: black;
  color: #fff;
  min-width: 270px;
  left: -40%;
  text-align: center;
  padding: 0.2rem 0.6rem;
  border-radius: 10px;
  display: none;
  transition: all 1250ms;
}

.accept_button:hover + .accept_button_hover {
  display: block;
}
.element_hover_select {
  background-color: #e2e2e2;
}
.element_hover:hover {
  background-color: #e2e2e2;
}

.element_hover_div {
  overflow: hidden;
}
.element_hover_div div {
  margin: 0;
  padding: 0.5rem;
}
.element_hover_div div:hover {
  background-color: #0d6efd;
  color: white !important;
}

.popuptitle {
  color: #828282;
  font-size: 0.9rem;
}
.popuptitlevalue {
  color: #27303f;
  font-weight: 600;
  margin-bottom: 0.3rem;
  font-size: 0.8rem;
}

.AcceptPopup {
  display: flex;
  justify-content: space-between;
  /* gap: 1rem; */
  margin-bottom: 0.1rem;
}

.AcceptPopupTitle {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.acceptMid {
  display: flex;
  justify-content: space-between;
}

.acceptButton {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
}
.acceptButton div {
  background-color: #5541d7;
  color: white;
  padding: 0.7rem;
  min-width: 15rem;
  max-width: 20rem;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}
.acceptButton2 {
  background-color: #fff !important;
  color: #5541d7 !important;
  border: 2px solid #5541d7;
  font-weight: bold;
}

.headerInPublicFrom {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
  /* padding-left: 1rem;
  padding-top: 1rem; */
  font-size: 1.5rem;
  background-color: rgb(247, 248, 255);
}

.flexContainerInPublic {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.sites__box {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  /* margin-bottom: 2rem; */
  width: 42rem;
  padding: 1rem;
}

.sites__box_1 {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  /* margin-bottom: 2rem; */
  width: 100%;
  padding: 1rem;
  justify-content: space-between;
}

.sites__box label,
.sites__box_1 label {
  margin: 0;
  color: #808080;
  font-weight: 700;
}

.sites__box span,
.sites__box_1 span {
  font-size: 0.9rem;
}

.sites__box input,
textarea,
select {
  width: 80%;
  outline: none;
  border: 1px solid black;
  background-color: #fff;
  border-radius: 10px;
  padding: 0.5rem;
}

.sites__box_1 input,
textarea,
select {
  width: 100%;
  outline: none;
  border: 1px solid black;
  background-color: #fff;
  border-radius: 10px;
  padding: 0.5rem;
}

.sites__button {
  display: flex;
  justify-content: space-between;
}

.sites__button button {
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  min-width: 40%;
  font-weight: 600;
  border-radius: 50px;
}

.sites__button_1 button {
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  min-width: 40%;
  font-weight: 600;
  border-radius: 50px;
  min-width: 8rem;
}

.sites__button :nth-child(2),
.sites__button_1 :nth-child(2) {
  color: white;
  background-color: #5541d7;
}

.container__popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: transparent;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  animation: sliderMove 250ms forwards ease-in-out;
  backdrop-filter: blur(01px);
}
@keyframes sliderMove {
  0% {
    top: -100%;
  }
  100% {
    top: 0%;
  }
}
.container__popup::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgb(34, 30, 30);
  opacity: 0.2;
  left: 0;
  top: 0;
  margin: auto;
  z-index: -1;
}

.container__selector {
  background-color: #fff;
  padding: 3rem 2rem;
  border-radius: 10px;
  position: relative;
}
.container__title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
}

.container__drop {
  margin: auto;
  width: 400px;
  margin-top: 0rem;
  padding: 2rem;
}
.container__button {
  margin: auto;
  display: flex;
  justify-content: center;
}

.container__button button {
  padding: 0.4rem 3rem;
  border-radius: 10px;
  color: white;
  font-weight: 600;
  border: none;

  background-color: rgb(57, 54, 141);
}

.container__cross {
  position: absolute;
  right: 5%;
  top: 7%;
  cursor: pointer;
}

.name_hover_copy {
  position: relative;
  /* overflow: hidden; */
  transition: all 250ms display;
}
.name_hover_copy:hover .copy_link {
  display: block;
}
.copy_link {
  display: none;
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  margin: auto;
  width: max-content;
  height: 120%;
  background-color: black;
  color: white;
  padding: 0.7rem 0.9rem;
  vertical-align: middle;
  line-height: 10px;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
  font-weight: 600;
  /* display: none; */
}

.parentofOpenLink {
  position: relative;
  /* overflow: hidden; */
}

.openLinkSet,
.closeLinkSet {
  transition: all 250ms ease-in;
  position: relative;
}
.openLinkSet {
  height: 5rem;
  top: -10%;
}

.openLinkSet :first-child {
  margin-top: 0.2rem;
}

.openLinkSet > div > span,
.closeLinkSet > div > span {
  text-align: left;
  width: 100%;
  float: right;
  padding: 0.3rem;
  cursor: pointer !important;
  background: none;
  color: rgb(215, 240, 240);
  white-space: nowrap;
  text-overflow: ellipsis;
  font: inherit;
}

.openLinkSet > div {
  height: 5rem;
  position: absolute;
  animation: openIn 150ms forwards;
  right: 0;
  width: 70%;
  z-index: -1;
}

.closeLinkSet > div {
  animation: closeIn 550ms forwards;
  position: absolute;
}

@keyframes openIn {
  0% {
    top: -50%;
    z-index: -1;
  }
  90% {
    z-index: -1;
  }
  100% {
    z-index: 100;
    top: 0%;
  }
}

@keyframes closeIn {
  0% {
    top: 0;
  }
  100% {
    top: -100%;
  }
}

/* Popup css */

.meta-container {
  padding: 2rem 3rem;
}

.meta-container-list {
  padding: 1.5rem 2rem;
}

.meta-container-element {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  margin: 0.5rem 2rem;
}

.meta-container-element > input {
  padding: 0.5rem;
  border-radius: 8px;
  border: 1px solid #828282;
}
.meta-container-element > label {
  font-family: Lato;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.meta-container-element > input,
.meta-container-element > textarea {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #6b7280;
}

.meta-content-list-details {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #6b7280;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
}

.meta-content-list-details > div {
  display: flex;
  justify-content: center;
  /* width: 15rem; */
  min-width: 10rem;
}

.meta-content-list-details > div > :nth-child(2) {
  flex: 1;
  text-align: left;
}

.meta-content-list-details > div > :nth-child(1) {
  font-weight: 600;
  color: #252733;
  margin-right: 0.25rem;
}

.send_email_select {
  border-radius: 10px;
  box-shadow: #2948980d 0px 3px 8px;
  background: #fefeff;
  height: 26px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #070b28;
}

.meta-container-list-limit {
  text-align: center;
  margin-bottom: 1rem;
}
.meta-content-container {
  text-align: center;
  font-family: Lato;
  font-size: 17px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
}

.meta-content-container-footer {
  text-align: center;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  margin-top: 1rem;
}

.meta-container-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.header-1 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 550;
  color: #828282;
}

.header-2 {
  width: max-content;
  margin-right: 15%;
}
.prime-cat,
.meta-des {
  margin-bottom: 0.5rem;
}
.meta-des,
.prime-cat {
  font-size: 0.9rem;
  color: #252733;
}
.meta-des span,
.prime-cat span {
  color: #828282;
  font-weight: 500;
  margin-right: 5px;
  font-size: 1rem;
}

.meta-details {
  display: grid;
  grid-template-columns: auto auto;
  gap: 5rem;
}
.grid-1 {
  width: 100%;
}

.grid-slice,
.grid-slice-2 {
  padding: 0.5rem;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
}
.grid-slice-2 {
  display: block;
}
.grid-slice-2 :first-child {
  margin-right: 10px;
}
.grid-slice {
  color: #252733;
}
.grid-slice :first-child {
  color: #9fa2b4;
  font-weight: 400;
  width: 12rem;
}

.channelInHeader {
  position: absolute;
  background-color: white;
  min-width: 15rem;
  max-width: 20rem;
  border: 1px solid grey;
  z-index: 1000;
  min-height: max-content;
  border-radius: 10px;
}

/* .channelInHeader :not(:first-of-type) {
  border-top: 1px solid grey;
} */

.channelInHeaderChild {
  cursor: pointer;
  /* min-height: 4rem; */
  padding: 0.5rem 0.2rem 0.1rem 0.2rem !important;
}
.NamesInWebSite {
  cursor: pointer;
  padding: 0 0 0 0.5rem !important;
  border-bottom: 1px solid #828282;
  line-height: 0;
  margin: 0.5rem 0 0rem 0;
}

.channelBackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: rgb(117, 117, 117);
  left: 0;
  width: 100%;
  opacity: 0.1;
  z-index: -1;
}

.NameCircle,
.NameCircle2 {
  /* background-color: rgb(45, 45, 45); */
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.NameCircle2 {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.8rem;
}

.webNameInChat {
  margin-right: 0.2rem;
  font-weight: 600;
  font-size: 0.89rem;
  color: #4f4f4f;
}

.linkTypeInfo:hover + .linkTypeHover,
.catInfo:hover + .catHover,
.sortBy:hover + .sortByHover,
.spamRatingInfo:hover + .spamRatingHover,
.typeInfo:hover + .typeHover,
.domainRatingInfo:hover + .domainRatingHover,
.pageViewInfo:hover + .pageViewHover,
.relTagInfo:hover + .relTagHover,
.domainAuthorityInfo:hover + .domainAuthorityHover,
.languageInfo:hover + .languageHover,
.countryInfo:hover + .countryHover,
.paidPriceInfo:hover + .paidPriceHover {
  display: block;
}

.sortByHover,
.catHover,
.linkTypeHover,
.spamRatingHover,
.typeHover,
.domainRatingHover,
.pageViewHover,
.relTagHover,
.domainAuthorityHover,
.languageHover,
.countryHover,
.paidPriceHover {
  position: absolute;
  left: 100%;
  top: 0;
  width: 20rem;
  color: black;
  background-color: rgb(226, 224, 224);
  font-weight: bold;
  font-size: 0.9rem;
  padding: 0.5rem;
  z-index: 200;
  border-radius: 10px;
  display: none;
}

.pageViewHover,
.relTagHover {
  left: -50%;
  top: -110%;
}

.paidPriceHover {
  left: -50%;
  top: -140%;
}

.domainRatingHover,
.languageHover,
.countryHover {
  width: 15rem;
}

.freeGuestHover,
.linkExchangeHover,
.contactHover,
.usersHover,
.acceptedLinkHover,
.verifiedDomainHover,
.exchangeHover,
.exchangePostHover {
  display: none;
}

.freeGuest:hover + .freeGuestHover,
.linkExchange:hover + .linkExchangeHover,
.contact:hover + .contactHover,
.users:hover + .usersHover,
.acceptedLink:hover + .acceptedLinkHover,
.verifiedDomain:hover + .verifiedDomainHover,
.exchangePost:hover + .exchangePostHover {
  display: block;
}

.supportContainer {
  display: flex;
  flex-direction: column;
  margin-left: 15%;
}

.supportContainer > span {
  padding: 0.5rem;
  font-size: 0.9rem;
  cursor: pointer;
}

.supportContainer > span:hover {
  background-color: rgb(215, 240, 240);
  color: black;
}
.sortTable {
  position: relative;
}
.sortTable > div {
  /* float: right; */
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
}

.printNotesHere {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.inputInFindBacklinks {
  padding: 1.2rem 1rem !important;
}

.titleInFindBacklinks {
  font-weight: 600;
  color: rgb(173, 173, 173);
  margin-bottom: 0.5rem;
}

.linkly_fixed_error_popup {
  display: flex;
  height: 85%;
  align-items: center;
  justify-content: center;
}

.linkly_fixed_error_popup > div {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #00000050;
  width: 60%;
  color: red;
  font-weight: 600;
}
